import {
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Person, PersonSource, PersonTypeId } from "cwpersontypes";
import { useMediaQuery } from "@mui/material";
import classes from "./PeopleContact.module.scss";
import { GetPeopleResult, getPeople } from "./getPeople";
import PeopleContext from "../../contexts/PeopleContext";
import { convertToCsvData, getFieldsForRequest } from "./peopleHelper";
import DownloadModal from "./DownloadModal";
import { contactFields } from "../../dashBoard/settings/importData/importHelper";
import ContactHeader from "./ContactHeader";
import ContactBody from "./ContactBody";
import ContactFooter from "./ContactFooter";

type Props = {
  leadSources: PersonSource[];
};

const PeopleContact = ({ leadSources }: Props) => {
  const [people, setPeople] = useState<Person[]>([]);
  const [totalPeople, setTotalPeople] = useState<number>(0);
  const [rowLimit, setRowLimit] = useState<number>(25);
  const [page, setPage] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [showColumns, setShowColumns] = useState<number[]>([2, 3]);
  const [progress, setProgress] = useState<number | null>(null);

  const canceledRef: MutableRefObject<boolean> = useRef(false);
  const selectedFilters: MutableRefObject<string | null> = useRef(null);

  const { filters, setFilters } = useContext(PeopleContext);
  const isMobile = useMediaQuery("(max-width: 900px)");

  const getPeopleData = useCallback(
    async (rowLimit: number, offset: number) => {
      setLoading(true);
      filters.pagination = { limit: rowLimit, offset: offset };
      filters.phoneValid = true;
      filters.emailValid = true;
      const fields: string[] = getFieldsForRequest(showColumns);
      const people: GetPeopleResult = await getPeople(filters, fields);

      setPeople(people.peopleData);
      setTotalPeople(people.totalPeople);
      setLoading(false);
    },
    [filters, showColumns]
  );

  const getSelectedFilters = useCallback(() => {
    const filtersArray: string[] = [];
    if (filters.leadSourceIds) {
      filters.leadSourceIds.forEach((leadId: string) => {
        const leadSource = leadSources.find((ls) => ls.uuid === leadId);
        if (leadSource?.sourceName) {
          filtersArray.push(leadSource.sourceName);
        }
      });
    }

    if (filters.personTypeIds) {
      filters.personTypeIds.forEach((typeId: string) => {
        Object.entries(PersonTypeId).forEach(([type, id]) => {
          if (typeId === id) {
            filtersArray.push(type);
          }
        });
      });
    }

    if (filters.inputLocation) {
      filtersArray.push(filters.inputLocation.address.toUpperCase());
      filtersArray.push(`${filters.inputLocation.radius} MILES`);
    }

    selectedFilters.current = filtersArray.join(", ");
  }, [
    filters.inputLocation,
    filters.leadSourceIds,
    filters.personTypeIds,
    leadSources,
  ]);

  useEffect(() => {
    setPage(0);
    getSelectedFilters();
  }, [filters, getSelectedFilters]);

  useEffect(() => {
    getPeopleData(rowLimit, rowLimit * page);
  }, [getPeopleData, page, rowLimit]);

  const handleDownload = async () => {
    setProgress(0);
    canceledRef.current = false;
    const fields = getFieldsForRequest(showColumns);
    const maxLimit = 500;
    const requests = Math.ceil(totalPeople / maxLimit);
    let copyFilters = { ...filters };

    let peopleData: Person[] = [];
    let remainPeople = totalPeople;

    for (let i = 0; i < requests; i++) {
      copyFilters.pagination = {
        limit: remainPeople >= maxLimit ? maxLimit : remainPeople,
        offset: i * maxLimit,
      };

      const people = await getPeople(copyFilters, fields);
      peopleData = peopleData.concat(people.peopleData);
      remainPeople -= maxLimit;
      if (canceledRef.current) break;
      else {
        setProgress(Math.round((i / requests) * 100));
      }
    }

    /**convert to csv and download  */
    const csvData = convertToCsvData(peopleData);
    const csvBlob = new Blob([csvData], { type: "text/csv" });
    const csvUrl = URL.createObjectURL(csvBlob);

    const downloadLink = document.createElement("a");
    downloadLink.href = csvUrl;
    downloadLink.download = "peopleContact.csv";
    downloadLink.textContent = "Download CSV";

    !canceledRef.current && downloadLink.click();
    setProgress(null);
    URL.revokeObjectURL(csvUrl);
  };

  return (
    <>
      <div className={classes["people_list_container"]}>
        <DownloadModal
          progress={progress}
          setProgress={setProgress}
          totalPeople={totalPeople}
          canceledRef={canceledRef}
        />

        <h2>People Contact</h2>

        <ContactHeader
          selectedFilters={selectedFilters}
          setFilters={setFilters}
          contactFields={contactFields}
          setShowColumns={setShowColumns}
          isMobile={isMobile}
        />

        <ContactBody
          loading={loading}
          isMobile={isMobile}
          people={people}
          showColumns={showColumns}
        />

        <ContactFooter
          isMobile={isMobile}
          handleDownload={handleDownload}
          totalPeople={totalPeople}
          rowLimit={rowLimit}
          setRowLimit={setRowLimit}
          page={page}
          setPage={setPage}
          loading={loading}
        />
      </div>
    </>
  );
};

export default PeopleContact;
